@keyframes shake {
  0% {
    transform: translate(-50%, -50%);
  }
  20% {
    transform: translate(-65%, -50%);
  }
  40% {
    transform: translate(-35%, -50%);
  }
  60% {
    transform: translate(-65%, -50%);
  }
  80% {
    transform: translate(-35%, -50%);
  }
  100% {
    transform: translate(-50%, -50%);
  }
}

@keyframes shakeRelative {
  0% {
    transform: translate(-50%, 0);
  }
  20% {
    transform: translate(-65%, 0);
  }
  40% {
    transform: translate(-35%, 0);
  }
  60% {
    transform: translate(-65%, 0);
  }
  80% {
    transform: translate(-35%, 0);
  }
  100% {
    transform: translate(-50%, 0);
  }
}

.shake {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 490px;
  animation: shake 0.4s;
}

.shakeRelative {
  transform: translate(-50%, 0);
  animation: shakeRelative 0.4s;
}

.authBox {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 490px;
}

.numeric-input::-webkit-outer-spin-button,
.numeric-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.numeric-input[type=number] {
  -moz-appearance: textfield;
}

.countryBox input {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

